import React from 'react'
import { Container, Row, Col, Image, Table } from 'react-bootstrap'
import IMAGE3 from './images/bt-visualizar-certificado.png'

const STYLE1 = {
    width: '100%',
    maxWidth: 900,
    padding: 15,
    margin: '10px auto'
}

export default (props) => {
    return (
        <Container fluid="md" style={STYLE1}>
            <Row>
                <Col>
                    <Table bordered style={{ fontSize: 13, marginTop: 15 }}>
                        <tbody><tr bgcolor="#d51218" style={{ color: '#FFFFFF' }}>
                            <th style={{ padding: 5 }}>Beneficiário</th>
                            <th style={{ padding: 5 }}>Produto</th>
                            <th style={{ padding: 5 }}>Certificado</th>
                        </tr>
                            { props.rows.map((response, key) => {
                                    return <tr key={key}>
                                        <td style={{ padding: 5 }}>
                                            <b>Nome: </b>{response.fullName.toUpperCase()}<br />
                                            <b>CPF: </b>{response.cpf}<br />
                                            <b>Telefone: {response.phone}</b>
                                        </td>
                                        <td style={{ padding: 5, fontWeight: 'bold' }}>
                                            {response.name}
                                            <br />
                                            {response.statusSubscription === 'ATIVO' ?
                                                <span style={{ color: 'green' }}>{response.statusSubscription}</span>
                                                :
                                                <span style={{ color: 'red' }}>{response.statusSubscription}</span>
                                            }
                                        </td>
                                        <td style={{ padding: 5 }}>
                                            <div align="center">
                                                <span style={{ cursor: 'pointer' }} onClick={(e) => window.open(`/certificate/${response.extref}`, "_blank")}>
                                                    <Image src={IMAGE3} fluid />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}