import React, { Component } from 'react'
import { Container, Navbar } from 'react-bootstrap'
import IMAGE1 from './images/logo.png'

export default class Menu extends Component {

    /**
     * Render Component
     */
    render() {
        return (
            <Navbar expand="md" style={{ backgroundColor: '#ec1c23' }}>
                <Container fluid="md">
                    <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src={IMAGE1}
                        width="180"
                        height="40"
                        className="d-inline-block align-top"
                    />
                    </Navbar.Brand>
                </Container>
            </Navbar>
        );
    }
}