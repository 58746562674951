import axios from 'axios'
import moment from 'moment'

import {
    URL_BACKEND,
    BRAND_NAME,
    REQUESTER_NAME
} from '../../constants'

const HEADERS = {
    'Content-Type': 'application/json',
    'X-FS-Brand-Name': BRAND_NAME,
    'X-FS-Requester-Name': REQUESTER_NAME
}

/**
 * Function Get Search
 * @param {int} value 
 * @param {string} partner 
 */
export async function getSearchClient(partner, value) {
    try {
        const response = await axios.get(`${URL_BACKEND}/bff/api/v2/insurance/client/search/cpf/${value}`, { headers: HEADERS });

        let rowsVivo = [];
        let rowsTim = [];

        response.data.data.forEach((response) => {
            response.policies.forEach((response) => {
                if (response.bundle.partner_id === 4) {
                    rowsVivo.push({
                        id: response.id,
                        fullName: response.fullname,
                        cpf: response.cpf,
                        name: response.bundle.name || '',
                        value: response.bundle.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || '',
                        phone: response.phone || '',
                        validityStart: response.inicio_vigencia !== null ? moment(response.inicio_vigencia).format('DD/MM/YYYY') : '',
                        validityEnd: response.final_vigencia !== null ?  moment(response.final_vigencia).format('DD/MM/YYYY') : '',
                        statusSubscription: (response.actived === 'ACTIVE') ? 'ATIVO' : 'CANCELADO',
                        subscriptionId: response.subscription_id || '',
                        insurancePolicyNumber: response.bundle.policy_number || '',
                        extref: response.extref
                    });
                }

                if(response.bundle.partner_id === 7){
                    rowsTim.push({
                        id: response.id,
                        fullName: response.fullname,
                        cpf: response.cpf,
                        name: response.bundle.name || '',
                        value: response.bundle.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || '',
                        phone: response.phone || '',
                        validityStart: response.inicio_vigencia !== null ? moment(response.inicio_vigencia).format('DD/MM/YYYY') : '',
                        validityEnd: response.final_vigencia !== null ?  moment(response.final_vigencia).format('DD/MM/YYYY') : '',
                        statusSubscription: (response.actived === 'ACTIVE') ? 'ATIVO' : 'CANCELADO',
                        subscriptionId: response.subscription_id || '',
                        insurancePolicyNumber: response.bundle.policy_number || '',
                        extref: response.extref
                    });
                }
            });
        });

        return {
            status: true,
            rows: (partner === 'vivo')? rowsVivo : rowsTim,
        }

    } catch (error) {

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
    }
}

/**
 * Function Get Certificate
 * @param {int} extref 
 */
export async function getCertificate(extref) {
    try {
        const response = await axios.get(`${URL_BACKEND}/bff/api/v2/insurance/policy/search/${extref}`, { headers: HEADERS });

        let luckNumber = response.data.luck_number.length > 0 ? response.data.luck_number[0].lotery_number : '';

        let products = [];

        response.data.bundle.products.forEach((response) => {
            products.push({
                id: response.id,
                lack: response.lack !== null ? response.lack : '',
                name: response.name !== null ? response.name : '',
                priority: response.priority !== null ? response.priority : '',
                coverage_value: response.coverage_value !== null ? response.coverage_value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '',
                deductible: response.deductible !== null ? response.deductible.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '',
                pos: response.pos !== null ? response.pos.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '',
                value: response.value !== null ? response.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''
            });
        });     

        let addressRisk = {
            address: response.data.address.description,
            number: response.data.address_number,
            complement: response.data.address_complement,
            cep: (response.data.address.cep.length < 8) ? response.data.address.cep.padStart(8, 0) : response.data.address.cep.toString(),
            neighborhood: response.data.address.neighborhood.description,
            city: response.data.address.neighborhood.city.description,
            uf: response.data.address.neighborhood.city.uf.code
        };

        let monthlyPrizeWithoutIOF = response.data.bundle.monthly_prize_without_iof !== null ? response.data.bundle.monthly_prize_without_iof.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '';
        let iofPrize = response.data.bundle.iof_prize !== null ? response.data.bundle.iof_prize.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '';
        let monthlyDraw = response.data.bundle.valor_sorteio !== null ? response.data.bundle.valor_sorteio.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '';

        return {
            status: true,
            statusCertificate: response.data.actived,
            partnerId: response.data.bundle.partner_id,
            companyCode: parseInt(response.data.bundle.company_code),
            bundleId: response.data.bundle_id,
            emissionData: moment().format('DD/MM/YYYY'),
            createdAt: moment(response.data.created_at).format('DD/MM/YYYY'),
            luckNumber: luckNumber,
            certificateNumber: response.data.insurance_policy_number,
            policyNumber: response.data.bundle.policy_number,
            validityStart: response.data.incio_vigencia !== null ? moment(response.data.incio_vigencia).format('DD/MM/YYYY') : '',
            validityEnd: response.data.final_vigencia !== null ?  moment(response.data.final_vigencia).format('DD/MM/YYYY') : '',            
            fullName: response.data.fullname,
            cpf: response.data.cpf,
            dateOfBirth: response.data.birth_date !== null ?  moment(response.data.birth_date).format('DD/MM/YYYY') : '',
            phone: response.data.phone,
            iof: response.data.bundle.iof,
            products: products,
            addressRisk: addressRisk,
            monthlyInsurancePremium: response.data.bundle.value !== null ? response.data.bundle.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '',
            monthlyPrizeWithoutIOF: monthlyPrizeWithoutIOF,
            iofPrize: iofPrize, 
            monthlyDraw: monthlyDraw,
            monthlyDrawText: response.data.bundle.valor_sorteio_extenso
        }

    } catch (error) {

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
    }
}