import React from 'react'
import { maskCPF } from '../../../utils'
import NumberFormat from 'react-number-format'
import ErrorMessage from '../../Errors'
import { Form, Col, Row, Button } from 'react-bootstrap'

const STYLE1 = {
    fontSize: 12
}

const STYLE2 = {
    borderRadius: 0,
    fontSize: 14
}

const STYLE3 = {
    background: '#ec1c23',
    borderRadius: 0,
    fontSize: 14
}

export default (props) => {
    return (
        <Row style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <Col>
                <Form onSubmit={props.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label style={STYLE1}>
                                <strong>CPF</strong>
                            </Form.Label>
                            <NumberFormat
                                className="form-control"
                                type="text"
                                name="cpf"
                                format={maskCPF}
                                maxLength={14}
                                value={props.cpf}
                                onChange={props.handleChange}
                                style={STYLE2}
                                autoFocus={true}
                                required
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label style={STYLE1}>
                                <strong>Senha</strong>
                            </Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                value={props.password}
                                onChange={props.handleChange}
                                style={STYLE2}
                                required
                            />
                        </Form.Group>
                    </Form.Row>
                    <ErrorMessage display={props.displayErrorMessage} onCloseErrorMessage={(e) => props.onCloseErrorMessage(e)} message={props.errorMessage} />
                    <Button variant="danger" type="submit" size="lg" block style={STYLE3}>
                        CONSULTAR
</Button>
                </Form>
            </Col>
        </Row>
    );
}