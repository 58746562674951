import React from 'react'

import IMAGE1 from './images/assina5.3.png'
import IMAGE2 from './images/assinatura6.3.jpg'
import IMAGE3 from './images/novo-logo-mapfre.jpg'
import IMAGE4 from './images/index.gif'
import IMAGE5 from './images/bg_cancelada.gif'

export default (props) => {
    return (
        <div>
            <table width={800} border={0} align="center" cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr>
                        <td height={20} align="right" valign="middle">
                            <a href="/#" className="borda" onClick={() => window.print()}><img alt="" src={IMAGE4} height={10} width={20} />
                                Imprimir certificado
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            { props.statusCertificate === 'CANCELED' &&
                <table width={780} border={0} align="center" cellPadding={0} cellSpacing={0} style={{ marginBottom: 10 }}>
                    <tbody>
                        <tr>
                            <td>
                                <img src={IMAGE5} alt="" style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: '30%',
                                    width: '80%'
                                }} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
            <table width={595} border={0} align="center" cellPadding={0} cellSpacing={0} className="borda">
                <tbody>
                    <tr>
                        <td bgcolor="#CCCCCC"> </td>
                        <td align="right" valign="middle" bgcolor="#CCCCCC"> </td>
                        <td bgcolor="#CCCCCC"> </td>
                    </tr>
                    <tr>
                        <td width={32} height={100}> </td>
                        <td valign="bottom">
                            <table width={800} border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="80%" height={80} rowSpan={2}>
                                            <h1 id="h1Certificado">Certificado Individual do seguro</h1>
                                            <font color="DB0000">Acidentes pessoais</font>
                                            <div style={{ padding: '5px 0 0 3px' }}>
                                                <strong>Seguro MAPFRE AP Concierge</strong><br />
                                                <strong>Data de Emissão: </strong>{props.emissionData}
                                            </div>
                                        </td>
                                        <td height={80} rendered="false">
                                            <br /><strong>Número da Sorte</strong><br />
                                            <span className="tituloValorBold">{props.luckNumber}</span>
                                            <br /><strong>Sorteio Mensal</strong><br />
                                            <span className="tituloValorBold">{props.monthlyDraw}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td width={13} />
                    </tr>
                    <tr>
                        <td width={32} align="center" />
                        <td align="left">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left"><strong>Apólice: </strong></td>
                                        <td width="25%" align="left"><strong>Certificado/Proposta Nº: </strong></td>
                                        <td width="25%" align="left"><strong>Grupo/Ramo: </strong></td>
                                    </tr>
                                    <tr>
                                        <td align="left">{props.policyNumber}</td>
                                        <td align="left">{props.certificateNumber}</td>
                                        <td align="left">09/82</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left">
                                            <strong>Vigência início às 24h do dia:</strong>
                                        </td>
                                        <td width="50%" align="left">
                                            <strong>Término de Vigência às 24h do dia:</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="left">{props.validityStart}</td>
                                        <td align="left">{props.validityEnd}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={32} align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="50%" valign="top"><strong>Dados do Estipulante</strong></td>
                                        <td width="25%" valign="top"><strong>CNPJ</strong></td>
                                        <td width="25%" valign="top"><strong>Pró-labore</strong></td>
                                    </tr>
                                    <tr>
                                        <td valign="top">TIM CELULAR S.A</td>
                                        <td valign="top">04.206.050/0001-80</td>
                                        <td valign="top">37,5% - R$ 2,54</td>
                                    </tr>
                                    <tr><td valign="top" colSpan={3}><strong>Endereço Completo:</strong></td></tr>
                                    <tr><td valign="top" colSpan={3}>Av. Giovanni Gronchi, 7143 – Vila Andrade – São Paulo – SP – Cep: 05724-006</td></tr>
                                    <tr>
                                        <td width="50%" valign="top"><strong>Dados do corretor</strong></td>
                                        <td width="25%" valign="top"><strong>Cód. SUSEP</strong></td>
                                        <td width="25%" valign="top"><strong>CNPJ</strong></td>
                                    </tr>
                                    <tr>
                                        <td valign="top">FS Insurance Administradora e Corretora de seguros Ltda</td>
                                        <td valign="top">15414.000959/2008-16</td>
                                        <td valign="top">01.241.789/0001-35</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td colSpan={2} valign="top"><strong>Dados do(a) Segurado(a) Titular</strong></td>
                                    </tr>
                                    <tr>
                                        <td width="50%" height={15} valign="top">Nome:</td>
                                        <td width="50%" height={15} valign="top">CPF:</td>
                                    </tr>
                                    <tr>
                                        <td height={25} valign="top">{props.fullName}</td>
                                        <td height={25} valign="top">{props.cpf}</td>
                                    </tr>
                                    <tr>
                                        <td height={15} valign="top">Data Nascimento:</td>
                                        <td height={15} valign="top">Telefone (DDD):</td>
                                    </tr>
                                    <tr>
                                        <td height={15} valign="top">{props.dateOfBirth}</td>
                                        <td height={15} valign="top">{props.phone}</td>
                                    </tr>
                                    <tr>
                                        <td height={15} valign="top">Endereço: {props.addressRisk.address} {props.addressRisk.number} {props.addressRisk.neighborhood} {props.addressRisk.city} {props.addressRisk.uf} </td>
                                        <td height={15} valign="top">Cep: {props.addressRisk.cep}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={32} align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={15} colSpan={2} valign="top" className="titulo">Cobertura(s), Capital(is) Segurado(s) e Prêmio(s)</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <table id="sublist" className="rf-dt headerTable bordaBotom" style={{ width: '100%' }}><colgroup span={4} />
                                                <thead id="sublist:th" className="rf-dt-thd">
                                                    <tr id="sublist:0" className="rf-dt-hdr rf-dt-hdr-fst even-row">
                                                        <th id="sublist:j_idt35" className="rf-dt-hdr-c" style={{ width: '35%', textAlign: 'left' }}>Coberturas</th>
                                                        <th id="sublist:j_idt37" className="rf-dt-hdr-c" style={{ width: '30%' }}>Capital Segurado</th>
                                                        <th id="sublist:j_idt39" className="rf-dt-hdr-c">Carência*</th>
                                                        <th id="sublist:j_idt41" className="rf-dt-hdr-c">Franquia</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="sublist:tb" className="rf-dt-b">
                                                    {props.products.map((response, key) => {
                                                        return <tr id={`sublist:${key}`} key={key} className="rf-dt-r rf-dt-fst-r even-row">
                                                            <td id="sublist:0:j_idt50" className="rf-dt-c">
                                                                {response.name}</td>
                                                            <td id="sublist:0:j_idt52" className="rf-dt-c textCenter">
                                                                {response.value}</td>
                                                            <td id="sublist:0:j_idt58" className="rf-dt-c textCenter">
                                                                {response.deductible}</td>
                                                            <td id="sublist:0:j_idt54" className="rf-dt-c textCenter">
                                                                {response.coverage_value}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                                <tbody id="sublist:sc">
                                                    <tr>
                                                        <td style={{ display: 'none' }} />
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="left"><b>Periodicidade de pagamento:</b> Mensal</td>
                                        <td align="right">
                                            <b>Parcela(s):</b>12 parcelas de {props.monthlyInsurancePremium}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={32} align="center"> </td>
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="22%" height={15} valign="top" align="center">Prêmio mensal (sem IOF)</td>
                                        <td width="20%" height={15} valign="top" align="center">IOF - {props.iof}%
                                        </td>
                                        <td width="22%" height={15} valign="top" align="center">Prêmio mensal do seguro</td>
                                        <td width="35%" height={15} valign="top" align="center">Custeio</td>
                                    </tr>
                                    <tr>
                                        <td height={15} valign="top" align="center">
                                            <strong>{props.monthlyPrizeWithoutIOF}</strong>
                                        </td>
                                        <td valign="top" align="center">
                                            <strong>{props.iofPrize}</strong>
                                        </td>
                                        <td valign="top" align="center">
                                            <strong>{props.monthlyInsurancePremium}</strong>
                                        </td>
                                        <td valign="top" align="center">
                                            <strong>100% responsabilidade do Segurado</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={32} align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={80} width="100%" colSpan={3}>
                                            <p align="justify"><b>Forma de pagamento:</b> Débito dos créditos ou fatura mensal da minha linha telefônica.</p>
                                            <p align="justify"><b>* Carência:</b> Será aplicada a carência no caso de suicídio ou sua tentativa ocorrido nos primeiros 2 (dois) anos da contratação individual do Seguro, conforme dispõe o artigo 798 do Código Civil para todas as Coberturas. Além deste prazo de carência legal, serão aplicados prazos de carência adicionais às Coberturas, indicados no quadro acima, exceto para eventos em decorrência de Acidente Pessoal coberto.</p>
                                            <p align="justify"><b>** Assistência:</b> De acordo com o regulamento, parte integrante das condições contratuais. Regulamento da assistência disponível no site www.mapfre.com.br/mapfre-tim.</p>
                                            <p align="justify"><b>Sorteio Mensal:</b> O Segurado terá o direito de participar de 1 (um) sorteio mensal durante o período de vigência constante neste certificado, tendo como premiação individual, o valor de {props.monthlyDraw} ({props.monthlyDrawText}), bruto de Imposto de Renda. O Segurado concorrerá aos sorteios com o <b>NÚMERO DA SORTE</b> informado no certificado a partir do 2º (segundo) mês subsequente ao pagamento do Seguro, desde que posteriormente esteja com o pagamento em dia.</p>
                                            <p align="justify">Título de Capitalização, Modalidade Incentivo, emitido pela <b>MAPFRE CAPITALIZAÇÃO S/A.</b>, CNPJ 09.382.998/0001-00, Processo SUSEP n° 15414.000959/2008-16. Cessão de participação nos sorteios, sendo 1 (um) sorteio por mês . Valor da premiação será pago liquido de Imposto de Renda. Regulamento completo no site www.mapfrevivo.com.br.  Canal de Ouvidoria: 0800 7753240.</p>
                                            <p align="justify"><b>Atualização Monetária de Capitais e Prêmios:</b> A cada período de 12 (doze) meses, contados da data de início de vigência individual do Seguro, o Capital Segurado e o Prêmio do Seguro serão atualizados anualmente pela aplicação da variação positiva do IPCA/IBGE (Índice Nacional de Preços ao Consumidor Amplo), acumulado nos últimos 12 meses que antecedem 02 (dois) meses anteriores ao aniversário do Certificado Individual. </p>
                                            <p align="justify"><b>Beneficiário(s):</b> Para pagamento do Capital Segurado, será considerada a indicação de Beneficiário realizada pelo Segurado na Proposta de Adesão ou em documento posterior solicitando a inclusão e/ou alteração, devidamente assinada pelo Segurado. <b>Independente do vínculo de parentesco prevalecerá a indicação nominativa</b>. Na falta de indicação de Beneficiário, ou se por qualquer motivo não prevalecer a indicação feita, o pagamento do Capital Segurado será realizado de acordo com as Condições Contratuais. </p>
                                            <p align="justify">O certificado é válido somente com o pagamento do prêmio do seguro. O presente Certificado substitui e anula os certificados anteriores eventualmente emitidos pela Seguradora. </p>
                                            <p align="justify">Seguro garantido pela MAPFRE Seguros Gerais S.A., CNPJ 61.074.175/0001-38, Cód. SUSEP: 06238, Av das Nações Unidas, 14261 - 29º Andar - Ala A - São Paulo - SP. Processo(s) SUSEP: Acidentes Pessoais n.º 15414.000357/2011-64. <b>Este documento contém um resumo das condições gerais aplicáveis ao seguro. Restrições se aplicam a ele.</b> Consulte a íntegra das Condições Gerais do seguro no site www.mapfre.com.br/mapfre-tim. </p>
                                            <p align="justify">O(s) serviços(s) de assistência(s) será(ão) prestada(s) por empresa(s) contratada(s) pela Seguradora. O Regulamento do Sorteio (MAPFRE Capitalização S.A., CNPJ 09.382.998/0001-00, Processo SUSEP 15414.000959/2008-16, disponível no site www.mapfre.com.br/mapfre-tim. <b>O não pagamento do prêmio pode gerar o cancelamento do seguro. Este Seguro será por prazo determinado tendo a Seguradora a faculdade de não renovar a apólice na data de vencimento, sem devolução de prêmios pagos nos termos da apólice. Este plano é estruturado no Regime Financeiro de Repartição Simples, os prêmios pagos por todos os Segurados do plano, em um determinado período, deverão ser suficientes para pagar as indenizações decorrentes dos eventos ocorridos naquele período, portanto não existe constituição de Provisão Matemática de Benefícios a Conceder ou Reserva Técnica em nome de cada Segurado para ser devolvida em caso de cancelamento do Seguro.</b></p>
                                            <p align="justify">A aceitação do seguro estará sujeita à análise do risco. O registro deste produto na SUSEP não implica, por parte da autarquia, incentivo ou recomendação à sua comercialização. O segurado poderá consultar a situação cadastral de seu corretor de seguros no site www.susep.gov.br pelo número de seu registro na SUSEP, nome completo, CNPJ ou CPF. As condições contratuais/regulamento deste produto protocolizadas pela sociedade/entidade junto à SUSEP poderão ser consultadas no endereço eletrônico www.susep.gov.br, de acordo com o número de processo constante da apólice/proposta. </p>
                                            <p align="justify">As informações sobre o produto de Seguro vinculado a este certificado podem ser obtidas no Portal da SUSEP, no seguinte link: http://susep.gov.br/menu/servicos-ao-cidadao/sistema-de-consulta-publica-de-produtos. – SUSEP – Superintendência de Seguros Privados – Autarquia Federal responsável pela fiscalização, normatização e controle dos mercados de Seguro, previdência complementar aberta, capitalização, resseguro e corretagem de seguros. Telefone de Atendimento ao Público (gratuito): 0800 021 8484. – Alíquota de Imposto sobre Operações Financeiras estabelecidas pelo Decreto-Lei Nº 6.339, de 03.01.2008. – Em atendimento à Lei 12.741/12 informamos que incidem as alíquotas de 0,65% de PIS/Pasep e de 4% de COFINS sobre os prêmios de seguros, as contribuições a plano de caráter previdenciário ou os pagamentos destinados a planos de capitalização, deduzidos do estabelecido em legislação específica.</p>
                                            <p align="justify"><b>Para informações, dúvidas, reclamações, cancelamento, abertura de sinistro e acionamento da assistência, ligue:</b></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={32} align="center" />
                        <td align="center" valign="top">
                            <table width="100%" border={0} cellPadding={5} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr style={{ background: '#E4E4E4', height: 40 }}>
                                        <th align="center">SAC<br />24 Horas</th>
                                        <th align="center">Assistência e Sinistro<br />24 Horas</th>
                                        <th align="center" width="33%">Deficiente Auditivo e de Fala<br />24 Horas</th>
                                    </tr>
                                    <tr className="even-row">
                                        <td align="center">*144</td>
                                        <td align="center">0800 667 4624</td>
                                        <td align="center">0800 962 7373</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            <p><strong>E-mail:</strong> tim@faleconoscoseguros.com.br</p>
                                            <p align="justify">A Ouvidoria tem como objetivo atuar na defesa dos direitos dos consumidores para esclarecer, prevenir e solucionar conflitos que não foram solucionadas pelos canais de atendimento habituais. Atendimento MAPFRE de 2ª a 6ª-feira, exceto feriado, das 8 às 18hs) - 0800 775 1079. Deficiente Auditivo e de Fala 0800 962 7373.</p>
                                            <p>Central de atendimento TIM (Estipulante): *144 ou 1056</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td width={32} align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={10} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <div align="center" style={{ textAlign: 'center' }}><img alt="" src={IMAGE1} style={{ width: 160, position: 'relative', top: 22 }} />
                                                <div align="center" style={{ textAlign: 'center', position: 'relative', top: 25 }}><span className="fontDefaultSpacing">Raphael de Luca Junior</span></div>
                                                <div align="center" style={{ textAlign: 'center', position: 'relative', top: 25 }}><span className="fontDefaultSpacing">Diretor</span></div>
                                            </div>
                                        </td>
                                        <td valign="top">
                                            <div align="center" style={{ textAlign: 'center' }}><img alt="" src={IMAGE2} />
                                                <div align="center" style={{ textAlign: 'center' }}><span className="fontDefaultSpacing">Augustín David B. C. Valdés</span></div>
                                                <div align="center" style={{ textAlign: 'center' }}><span className="fontDefaultSpacing">Diretor</span></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td colSpan={3} align="right"><img alt="" src={IMAGE3} height={70} style={{ paddingTop: 7, paddingRight: 7, paddingBottom: 4 }} width={190} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}