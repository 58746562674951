import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import {
    validatorCPF,
} from '../../utils'

import {
    getSearchClient,
} from '../../services'

import Layout from '../../layout'
import Loading from '../../components/Loading'
import FormSendConsult from '../../components/Forms/SendConsult'
import TableBundle from '../../components/Tables/Bundles'
import Wallet from '../../components/Wallet'

import IMAGE1 from '../../components/Wallet/images/download.png'

const STYLE1 = {
    width: '100%',
    maxWidth: 900,
    padding: 15,
    margin: '10px auto'
}

const STYLE2 = {
    fontWeight: 'normal',
    color: '#424242',
    fontSize: 26,
    lineHeight: '30px',
    marginBottom: 25,
    textAlign: 'center'
}

const STYLE3 = {
    color: '#1f1f1f',
    fontSize: 17,
    lineHeight: '22px',
    fontWeight: 'bold'
}

const STYLE4 = {
    fontSize: 13,
    lineHeight: '22px'
}

const STYLE6 = {
    marginTop: 30
}

export default class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            errorMessage: null,
            displayErrorMessage: false,
            displayFormSendConsult: true,
            displayWallet: false,
            displayCertificate: false,
            titleSupport: '',
            partner: '',
            cpf: '',
            password: '',
            fullName: '',
            policyNumber: '',
            validityStart: '',
            validityEnd: '',
            rowsPolicies: []
        }

        this.onSelectPartner = this.onSelectPartner.bind(this);
        this.handleChangeFormSendConsult = this.handleChangeFormSendConsult.bind(this);
        this.handleSubmitFormSendConsult = this.handleSubmitFormSendConsult.bind(this);
    }

    /**
     * Component Did Mount
     */
    componentDidMount() {
        const partner = this.props.match.params.partner;
        this.onSelectPartner(partner);
    }

    /**
     * Function On Select Partner
     * @param {string} partner 
     */
    onSelectPartner = (partner) => {
        switch (partner) {
            case 'vivo':
                this.setState({
                    titleSupport: 'Envie e-mail para atendimentoaffinity@bbmapfre.com.br',
                    partner: partner
                });
                break;

            case 'tim':
                this.setState({
                    titleSupport: 'Ligue para *144 ou envie e-mail para tim@faleconoscoseguros.com.br',
                    partner: partner
                });
                break;

            case 'algar':
                this.setState({
                    titleSupport: 'Ligue para 0800 001 2088 ou envie e-mail para algar@faleconoscoseguros.com.br',
                    partner: partner
                });
                break;

            default:
                break;
        }
    }

    /**
    * On Close Error Message
    */
    onCloseErrorMessage = (e) => {
        this.setState({
            displayErrorMessage: false,
            errorMessage: null
        });
    }

    /**
     * Function On Click Button Print
     * @param {event} e 
     */
    onClickButtonPrint = (e, name, cpf, policyNumber, validityStart, validityEnd) => {
        let win = window.open('about:blank', "_new");
        win.document.open();
        win.document.write([
            '<html>',
            '   <head>',
            '   </head>',
            '   <body onload="window.print()" onafterprint="window.close()">',
            '   <div style="background-image: url(' + IMAGE1 + '); background-repeat: no-repeat; height: 400px; margin-bottom: 15px;"><p style="padding: 90px 20px 0px; color: rgb(255, 255, 255); font-size: 14px; font-family: Arial;"><span>' + name + '</span><br><span>CPF: ' + cpf + '</span><br><span>Apólice: '+ policyNumber +'</span><br><span>Início Vigência: ' + validityStart + '</span><br><span>Valido Até: ' + validityEnd + '</span><br></p></div>',
            '   </body>',
            '</html>'
        ].join(''));
        win.document.close();
        e.preventDefault();
    }

    /**
     * Handle Change Form SendConsult Certificates And Benefits
     * @param {event} e 
     */
    handleChangeFormSendConsult(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
     * Handle Submit Form SendConsult Certificates And Benefits
     * @param {event} e 
     */
    async handleSubmitFormSendConsult(e) {
        e.preventDefault();

        const { cpf, password, partner } = this.state;

        const validator = validatorCPF(cpf);

        if (!validator.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: validator.message
            });
            return;
        }

        this.setState({
            loading: true,
            displayWallet: false,
            displayTableBundle: false,
            rowsPolicies: [],      
            displayErrorMessage: false,
            errorMessage: null
        });

        if (validator.cpf.substr(7) !== password) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: 'Senha inválida, por favor tente novamente!'
            });
            return;
        }

        const response = await getSearchClient(partner, validator.cpf);

        if (!response.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response.message
            });
            return;
        }

        if (response.rows.length === 0) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: 'Cliente não encontrado. Tente novamente!'
            });
            return;
        }

        this.setState({
            loading: false,
            displayFormSendConsult: true,
            displayWallet: true,
            displayTableBundle: true,
            rowsPolicies: response.rows
        });
    }

    /**
     * Render Component
     */
    render() {

        const {
            loading,
            cpf,
            password,
            titleSupport,
            errorMessage,
            displayErrorMessage,
            displayFormSendConsult,
            displayTableBundle,
            displayWallet,
            rowsPolicies
        } = this.state;

        return (
            <Layout>
                <Loading display={loading} />
                <Container fluid="md" style={STYLE1}>
                    <Row>
                        <Col>
                            <h3 style={STYLE2}>Certificado e Carteirinha de Benefícios</h3>
                            <h5 style={STYLE3}>CONSULTE SEU CERTIFICADO E CARTEIRINHA</h5>
                            <p style={STYLE4}>O início da vigência do seguro ocorrerá após o
                            pagamento da 1ª parcela do seguro.<br />
                            Para ter acesso ao certificado individual do seguro e carteirinha, insira o seu CPF:</p>
                        </Col>
                    </Row>
                    <FormSendConsult
                        cpf={cpf}
                        password={password}
                        display={displayFormSendConsult}
                        errorMessage={errorMessage}
                        displayErrorMessage={displayErrorMessage}
                        onCloseErrorMessage={this.onCloseErrorMessage}
                        handleChange={this.handleChangeFormSendConsult}
                        handleSubmit={this.handleSubmitFormSendConsult}
                    />
                    <Row>
                        <Col>
                            <div style={STYLE6}>
                                <p style={STYLE4}>
                                    {/* Primeiro acesso ou esqueci minha senha? Clique aqui para gerar a senha.<br /><br /> */}
                                    <strong>Está com dificuldade de efetuar visualizar seu certificado e carteirinha?</strong><br />
                                    {titleSupport}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {displayWallet &&
                    <Wallet
                        rows={rowsPolicies}
                        onClickButtonPrint={(e, name, cpf, policyNumber, validityStart, validityEnd) => this.onClickButtonPrint(e, name, cpf, policyNumber, validityStart, validityEnd)}
                    />
                }

                {displayTableBundle &&
                    <TableBundle
                        rows={rowsPolicies}
                        onClickButtonPrint={(e) => this.onClickButtonPrint(e)}
                        onClickButtonCertificate={(e) => this.onClickButtonCertificate(e)}
                    />
                }
            </Layout>
        );
    }
}