import React from 'react'

import IMAGE1 from './images/assina5.3.png'
import IMAGE2 from './images/assinatura6.3.jpg'
import IMAGE3 from './images/novo-logo-mapfre.jpg'
import IMAGE4 from './images/index.gif'
import IMAGE5 from './images/bg_cancelada.gif'

export default (props) => {

    let hideFields = (props.createdAt > '31/07/2020');

    /** Roof */
    const roof = props.products.length > 0 ? props.products.filter(function (obj) { return obj.id !== 118 && obj.id !== 127; }) : [];
    roof.sort((a, b) => a.priority - b.priority);

    /** Sweepstakes */
    const sweepstakes = props.products.length > 0 ? props.products.filter(function (obj) { return obj.id === 118 || obj.id === 127; }) : [];

    return (
        <div>
            <table width={900} border={0} align="center" cellPadding={4} cellSpacing={0} style={{ marginBottom: 10 }}>
                <tbody>
                    <tr>
                        <td height={20} align="right" valign="middle">
                            <span onClick={() => window.print()} style={{ textDecoration: 'underline', fontSize: 12 }}>
                                <img src={IMAGE4} alt="" height={10} width={10} />
                                Imprimir certificado
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            { props.statusCertificate === 'CANCELED' &&
                <table width={900} border={0} align="center" cellPadding={4} cellSpacing={0} style={{ marginBottom: 10, textAlign: 'center' }}>
                    <tbody>
                        <tr>
                            <td>
                                <img src={IMAGE5} alt="" style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: '30%',
                                    width: '80%'
                                }} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
            <table width={900} border={0} align="center" cellPadding={4} cellSpacing={5} className="borda">
                <tbody>
                    <tr>
                        <td width={10} height={100}></td>
                        <td valign="bottom">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="80%" height={80} rowSpan={2}><h1 id="h1Certificado" style={{ fontWeight: 'bold' }}>Certificado Individual do Seguro</h1>
                                            <div style={{ paddingLeft: 3 }}>
                                                <strong>Seguro Proteção Financeira Conta Protegida</strong><br />
                                                <strong>Data de Emissão: </strong>{props.emissionData}
                                            </div>
                                        </td>
                                        { !hideFields &&
                                            <td style={{ display: 0 }} width="80%" height={80} rowSpan={2}>
                                                <br /><strong>Número da Sorte</strong><br />
                                                <span className="tituloValorBold">{props.luckNumber}</span>
                                                <br /><strong>Sorteio Mensal</strong><br />
                                                <span className="tituloValorBold">{props.monthlyDraw}</span>
                                            </td>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td width={13}> </td>
                    </tr>
                    <tr>
                        <td width={10} align="center"> </td>
                        <td align="left">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="33%" height={20} align="left"><strong>Certificado Nº: </strong></td>
                                        <td width="28%" height={20} align="left"><strong>Apólice: </strong></td>
                                        <td width="28%" height={20} align="left"><strong>Grupo/Ramo: </strong></td>
                                    </tr>
                                    <tr>
                                        <td width="50%" height={20} align="left">{props.certificateNumber}</td>
                                        <td width="50%" height={20} align="left">{props.policyNumber}</td>
                                        <td width="50%" height={20} align="left">0977</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left">
                                            <strong>Vigência início às 24h do dia:</strong>
                                        </td>
                                        <td width="50%" align="left">
                                            <strong>Término de Vigência às 24h do dia:</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="39%" height={20} align="left">{props.validityStart}
                                        </td>
                                        <td width="39%" height={20} align="left">{props.validityEnd}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center"> </td>
                    </tr>
                    <tr>
                        <td width={10} align="center"> </td>
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="28%" height={20} align="left"><strong>Dados do Estipulante</strong></td>
                                        <td width="28%" height={20} align="left"><strong>CNPJ:</strong></td>
                                        <td width="28%" height={20} align="left"><strong>P./L.</strong></td>
                                    </tr>
                                    <tr>
                                        <td width="17%" height={15} valign="top">Telefônica Brasil</td>
                                        <td width="17%" height={15} valign="top">02.558.157/0001-62</td>
                                        <td width="17%" height={15} valign="top" />
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center"> </td>
                    </tr>
                    <tr>
                        <td width={10} align="center"> </td>
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="28%" height={20} align="left"><strong>Endereço completo:</strong></td>
                                    </tr>
                                    <tr>
                                        <td width="17%" height={15} valign="top">Av Engenheiro Luiz Carlos Berrini, 1376 – Cidade Monções – São Paulo – Cep: 04571-936</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center"> </td>
                    </tr>
                    <tr>
                        <td width={10} align="center"> </td>
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="28%" height={20} align="left"><strong>Dados do corretor</strong></td>
                                        <td width="28%" height={20} align="left"><strong>CNPJ:</strong></td>
                                        <td width="28%" height={20} align="left"><strong>Cód. SUSEP</strong></td>
                                    </tr>
                                    <tr>
                                        <td width="17%" height={15} valign="top">Telefônica Corretora de Seguros Ltda</td>
                                        <td width="17%" height={15} valign="top">04.772.577/0001-72</td>
                                        <td width="17%" height={15} valign="top">10.0443484</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center"> </td>
                    </tr>
                    <tr>
                        <td align="center"> </td>
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={15} colSpan={2} valign="top"><strong>Dados do(a) Segurado(a) Titular</strong></td>
                                    </tr>
                                    <tr>
                                        <td width="17%" height={15} valign="top">Nome:</td>
                                        <td width="17%" height={15} valign="top">CPF:</td>
                                    </tr>
                                    <tr>
                                        <td height={15} valign="top">{props.fullName}</td>
                                        <td height={15} valign="top">{props.cpf}</td>
                                    </tr>
                                    <tr>
                                        <td width="17%" height={15} valign="top">Data Nascimento:</td>
                                        <td width="17%" height={15} valign="top">Telefone (DDD):</td>
                                    </tr>
                                    <tr>
                                        <td>{props.dateOfBirth}</td>
                                        <td>{props.phone}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center"> </td>
                    </tr>
                    <tr>
                        <td width={15} align="center"> </td>
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={15} colSpan={3} valign="top" className="titulo">Cobertura(s), Capital(is) Segurado(s) e Prêmio(s)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table id="sublist" className="rf-dt headerTable bordaBotom" style={{ width: '100%' }}>
                                                <colgroup span={5} />
                                                <thead id="sublist:th" className="rf-dt-thd">
                                                    <tr id="sublist:0" className="rf-dt-hdr rf-dt-hdr-fst even-row">
                                                        <th id="sublist:j_idt40" className="rf-dt-hdr-c" style={{ width: '35%', textAlign: 'left' }}>
                                                            Coberturas</th>
                                                        <th id="sublist:j_idt42" className="rf-dt-hdr-c" style={{ width: '30%' }}>
                                                            Capital Segurado (Saldo devedor até)</th>
                                                        <th id="sublist:j_idt44" className="rf-dt-hdr-c" style={{ width: '30%' }}>
                                                            Prêmio mensal (sem IOF)</th>
                                                        <th id="sublist:j_idt46" className="rf-dt-hdr-c" style={{ width: '30%' }}>
                                                            Carência*</th>
                                                        <th id="sublist:j_idt48" className="rf-dt-hdr-c" style={{ width: '30%' }}>
                                                            Franquia</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="sublist:tb" className="rf-dt-b">
                                                    {roof.map((response, key) => {
                                                        return <tr id={`sublist:${key}`} key={key} className="rf-dt-r rf-dt-fst-r even-row">
                                                            <td id="sublist:0:j_idt50" className="rf-dt-c">
                                                                {response.name}</td>
                                                            <td id="sublist:0:j_idt52" className="rf-dt-c textCenter">
                                                                {response.value}</td>
                                                            <td id="sublist:0:j_idt54" className="rf-dt-c textCenter">
                                                                {response.coverage_value}</td>
                                                            <td id="sublist:0:j_idt56" className="rf-dt-c textCenter">
                                                                {response.lack}</td>
                                                            <td id="sublist:0:j_idt58" className="rf-dt-c textCenter">
                                                                {response.deductible}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                                <tbody id="sublist:sc">
                                                    <tr>
                                                        <td style={{ display: 'none' }} />
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center"> </td>
                    </tr>
                    { !hideFields && 
                        <tr style={{ display: 0 }}>
                            <td width={10} align="center"> </td>
                            <td align="left" valign="top">
                                {sweepstakes.map((response, key) => {
                                    return <table width="100%" key={key} border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table id="sublist" className="rf-dt headerTable bordaBotom" style={{ width: '100%' }}>
                                                        <colgroup span={2} />
                                                        <thead id="sublist:th" className="rf-dt-thd">
                                                            <tr id="sublist:0" className="rf-dt-hdr rf-dt-hdr-fst even-row">
                                                                <th id="sublist:j_idt62" className="rf-dt-hdr-c" style={{ width: '30%', textAlign: 'left' }}>
                                                                    Sorteios</th>
                                                                <th id="sublist:j_idt64" className="rf-dt-hdr-c" style={{ width: '30%' }}>
                                                                    Valor dos Sorteios</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="sublist:tb" className="rf-dt-b">
                                                            <tr id="sublist:0" className="rf-dt-r rf-dt-fst-r even-row">
                                                                <td id="sublist:0:j_idt66" className="rf-dt-c">
                                                                    {response.name}</td>
                                                                <td id="sublist:0:j_idt68" className="rf-dt-c textCenter">
                                                                    {response.value}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody id="sublist:sc">
                                                            <tr>
                                                                <td style={{ display: 'none' }} />
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                })}
                            </td>
                            <td align="center"> </td>
                        </tr>
                    }
                    <tr>
                        <td width={10} align="center"> </td>
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="22%" height={15} valign="top" align="center">Prêmio mensal (sem IOF)</td>
                                        <td width="20%" height={15} valign="top" align="center">IOF - {props.iof}
                                        </td>
                                        <td width="22%" height={15} valign="top" align="center">Prêmio mensal do seguro</td>
                                        <td width="35%" height={15} valign="top" align="center">Custeio</td>
                                    </tr>
                                    <tr>
                                        <td height={15} valign="top" align="center">
                                            <strong>{props.monthlyPrizeWithoutIOF}</strong>
                                        </td>
                                        <td valign="top" align="center">
                                            <strong>{props.iofPrize}</strong>
                                        </td>
                                        <td valign="top" align="center">
                                            <strong>{props.monthlyInsurancePremium}</strong>
                                        </td>
                                        <td valign="top" align="center">
                                            <strong>100% responsabilidade do Segurado</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={10} align="center"> </td>
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <p align="justify"><strong>As coberturas de Perda de Renda por Desemprego Involuntário (elegível aos profissionais CLT, com registro em carteira ininterruptamente por no mínimo doze meses) e Perda de Renda por Incapacidade Física e Temporária (elegível aos profissionais liberais ou autônomos que comprovem renda) se aplicam somente para as respectivas Categorias Profissionais, relacionadas e descritas nas Condições Gerais do seguro e não se acumulam. </strong></p><div style={{ textDecoration: 'underline' }}><strong>Profissionais Liberais ou autônomos que não comprovarem renda na data da ocorrência do sinistro não terão direito a indenização de Perda de Renda por Incapacidade Física e Temporária.</strong></div><p />
                                            <p align="justify" />
                                            <p align="justify"><strong>*Carência: Para todas as coberturas será aplicada a carência no caso de suicídio, ou sua tentativa, ocorrido nos primeiros dois anos de contratação do seguro, ou de sua recondução, depois de suspenso, conforme dispõe o artigo 798 do Código Civil.</strong></p>
                                            { !hideFields &&
                                                <p align="justify" style={{ display: 0 }}><strong><u>**Sorteios Mensais:</u></strong> O segurado terá direito a participação em 1 (um) sorteio mensal no valor de {props.monthlyDraw} ({props.monthlyDrawText}) bruto de IR no último sábado de cada mês, de acordo com o Número da Sorte informado neste Certificado. O segurado garantirá sua participação nos sorteios a partir do 2º mês subsequente ao mês em que efetuar o pagamento do prêmio do seguro, e permanecerá participando enquanto o pagamento do prêmio estiver em dia.Consulte o manual do segurado com o regulamento do sorteio no site www.mapfrevivo.com.br </p>
                                            }
                                            <p align="justify">
                                            </p>
                                            <p align="justify"><strong>Beneficiário: </strong>
O beneficiário para a cobertura de Morte será (ao) o capital segurado será pago por metade ao cônjuge não separado judicialmente ou companheiro, e o restante aos herdeiros do segurado, obedecida a ordem da vocação hereditária.
O beneficiário para as coberturas de Desemprego Involuntário, Incapacidade Física Temporária por Acidente e Invalidez Permanente e Total por Acidente será o próprio segurado.
</p>
                                            <p align="justify"><strong>Início da Vigência Individual: </strong>O início de vigência do seguro será a partir das 24 horas da data de vencimento estipulada na fatura telefônica, desde que haja a quitação da mesma.</p>
                                            <p align="justify"><strong>Cancelamento Individual: </strong>O seguro será cancelado para o cliente que estiver inadimplente por mais de 90 dias, contados a partir da data de vencimento da fatura telefônica, onde conste a cobrança do seguro.</p>
                                            <p align="justify" />
                                            <p align="justify"><strong>Este documento será válido somente com o pagamento do prêmio do seguro.</strong> Seguro garantido pela <strong>MAPFRE SEGUROS GERAIS S/A</strong>, inscrita regularmente no CNPJ/MF sob o nº 61.074.175/0001-38 e Cód. SUSEP 06238 e Processo SUSEP 154.100431/2002-51 e comercializado pela Telefônica Corretora de Seguros Ltda – CNPJ 04.772.577/0001-72.
                                            Consulte a íntegra das Condições Gerais do seguro no site www.mapfre.com.br.</p>
                                            { !hideFields && 
                                                <p style={{ display: 0 }} align="justify">Sorteio vinculado a Título de Capitalização emitido pela MAPFRE Capitalização S/A. CNPJ 09.382.998/0001-00 e Processo SUSEP 15414.901717/2019-02. Manual do Segurado e Regulamento do Sorteio disponíveis no site www.mapfrevivo.com.br. A aceitação do seguro estará sujeita à análise do risco. O registro deste produto na SUSEP não implica, por parte da autarquia, incentivo ou recomendação à sua comercialização. O segurado poderá consultar a situação cadastral de seu corretor de seguros no site www.susep.gov.br pelo número de seu registro na SUSEP, nome completo, CNPJ ou CPF.</p>
                                            }
                                            <strong>O não pagamento do prêmio pode gerar o cancelamento do seguro. Este seguro é por prazo determinado tendo a Seguradora a faculdade de não renovar a apólice na data do vencimento, sem devolução dos prêmios pagos nos termos da apólice.</strong>
SUSEP – Superintendência de Seguros Privados – Autarquia Federal responsável pela fiscalização, normatização e controle dos mercados de Seguro, previdência complementar aberta, capitalização, resseguro e corretagem de seguros. Telefone de Atendimento ao Público (gratuito): 0800 021 8484.  As condições contratuais/regulamento deste produto protocolizadas pela sociedade/entidade junto à SUSEP poderão ser consultadas no endereço eletrônico www.susep.gov.br, de acordo com o número de processo constante da apólice/proposta. As informações sobre  o produto de seguro vinculado a este certificado podem ser obtidas no Portal da SUSEP, no seguinte link: http://susep.gov.br/menu/servicos-ao-cidadao/sistema-de-consulta-publica-de-produtos.
<p />
                                            <p align="justify">
                                                Para informações, dúvidas, reclamações, cancelamento, abertura de sinistro e acionamento da assistência, ligue:
                                                Central de relacionamento e SAC
</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center"> </td>
                    </tr>

                    <tr>
                        <td width={10} align="center"> </td>
                        <td align="left" valign="top">
                            <table id="sublist" className="rf-dt headerTable bordaBotom" style={{ width: '100%' }}>
                                <colgroup span={2} />
                                <thead id="sublist:th" className="rf-dt-thd">
                                    <tr id="sublist:0" className="rf-dt-hdr rf-dt-hdr-fst even-row">
                                        <th className="rf-dt-hdr-c" style={{ textAlign: 'left' }}><strong style={{ fontSize: 12 }}>Capitais e regiões metropolitanas</strong></th>
                                        <th className="rf-dt-hdr-c" style={{ textAlign: 'left' }}><strong style={{ fontSize: 12 }}>Demais localidades</strong></th>
                                        <th className="rf-dt-hdr-c" style={{ textAlign: 'left' }}><strong style={{ fontSize: 12 }}>SAC 24 Horas</strong></th>
                                        <th className="rf-dt-hdr-c" style={{ textAlign: 'left' }}><strong style={{ fontSize: 12 }}>Deficiente auditivo</strong></th>                                        
                                    </tr>
                                </thead>
                                <tbody id="sublist:tb" className="rf-dt-b">
                                    <tr id="sublist:0" className="rf-dt-r rf-dt-fst-r even-row">
                                        <td className="rf-dt-c">4002 7041 </td>
                                        <td className="rf-dt-c">0800 570 7041</td>
                                        <td className="rf-dt-c">0800 570 7045</td>
                                        <td className="rf-dt-c">0800 775 5045</td>
                                    </tr>
                                </tbody>
                                <tbody id="sublist:sc">
                                    <tr>
                                        <td style={{ display: 'none' }} />
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <p><strong>E-mail:</strong> atendimentoaffinity@bbmapfre.com.br</p>
                                            <p align="justify">Ouvidoria MAPFRE (2° a 6° feira, exceto feriado, das 9 às 18hs) 0800 775 1079, Deficiente Auditivo e de Fala 0800 962 7373. A Ouvidoria tem como objetivo atuar na defesa dos direitos dos consumidores para esclarecer, prevenir e solucionar conflitos que não foram solucionados pelos canais de atendimento habituais.</p>
                                            <p align="justify">Central de atendimento VIVO (Estipulante): 10315</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center"> </td>
                    </tr>
                    <tr>
                        <td width={10} align="center"> </td>
                        <td align="left" valign="top" style={{ paddingTop: 30 }}> 
                            <table width="100%" border={0} cellPadding={4} cellSpacing={10} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td valign="top" style={{ paddingBottom: 15 }}>
                                            <div align="center"><img src={IMAGE1} alt="" style={{ width: 185 }} />
                                                <div align="center" style={{ textAlign: 'center' }}><span className="fontDefaultSpacing">Raphael de Luca Junior</span></div>
                                                <div align="center" style={{ textAlign: 'center' }}><span className="fontDefaultSpacing">Diretor</span></div>
                                            </div>
                                        </td>
                                        <td valign="top" style={{ paddingBottom: 15 }}>
                                            <div align="center" style={{ textAlign: 'center' }}><img src={IMAGE2} alt="" />
                                                <div align="center" style={{ textAlign: 'center' }}><span className="fontDefaultSpacing">Augustín David B. C. Valdés</span></div>
                                                <div align="center" style={{ textAlign: 'center' }}><span className="fontDefaultSpacing">Diretor</span></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center"> </td>
                    </tr>
                    <tr>
                        <td colSpan={3} align="right"><img src={IMAGE3} alt="" height={70} style={{ paddingTop: 7, paddingRight: 7, paddingBottom: 4 }} width={190} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}