import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import Home from './pages/Home'
import Certificates from './pages/Certificates'

const PageNotFound = <div style={{ margin: 15 }}>
    <h1>Forbidden</h1>
    <p>You don't have permission to access this resource.</p>
    <hr />
</div>

export default () => (
    <BrowserRouter basename="/">
        <Switch>
            <Route path="/" exact={true} component={() => PageNotFound }  />
            <Route path="/mapfre-client-interface/public/vivo" exact={true} component={() => <Redirect to="/mapfre-client-interface/public/vivo/certificado" /> }  />
            <Route path="/mapfre-client-interface/public/tim" exact={true} component={() => PageNotFound } />
            <Route path="/mapfre-client-interface/public/tim/certificado" exact={true} component={() => PageNotFound } />
            <Route path="/mapfre-client-interface/public/:partner/certificado" exact={true} component={Home} />
            <Route path="/mapfre-client-interface/public/:partner/cancelar" component={() => PageNotFound }  />            
            <Route path="/certificate/:extref" exact={true} component={Certificates} />            
            <Route path="*" component={() => PageNotFound }  />
        </Switch>
    </ BrowserRouter>
);