import React, { Component } from 'react'
import Loading from '../../components/Loading'
import CertificateVivoProtectAccount from '../../components/Certificates/Vivo/ProtectedAccount'
import CertificateVivoHomeInsurance from '../../components/Certificates/Vivo/HomeInsurance'
import CertificateTimProtectedHouse from '../../components/Certificates/Tim/ProtectedHouse'
import CertificateTimConcierge from '../../components/Certificates/Tim/Concierge'
import CertificateTimHelpDesk from '../../components/Certificates/Tim/HelpDesk'

import {
    getCertificate
} from '../../services'

import './styles.css'

export default class Certificates extends Component {

    constructor(){
        super();

        this.state = {
            loading: true,
            displayCertificateVivoProtectedAccount: false,
            displayCertificateVivoHomeInsurance: false,
            displayCertificateTimProtectedHouse: false,
            displayCertificateTimConcierge: false,
            displayCertificateTimHelpDesk: false,
            emissionData: '',
            luckNumber: '',
            certificateNumber: '',
            policyNumber: '',
            validityStart: '',
            validityEnd: '',
            fullName: '',
            cpf: '',
            dateOfBirth: '',
            phone: '',
            iof: '',
            monthlyPrizeWithoutIOF: '',
            iofPrize: '',            
            monthlyInsurancePremium: '', 
            statusCertificate: '',
            monthlyDraw: '',
            monthlyDrawText: '',
            createdAt: '',
            products: [],
            addressRisk: []
        }
    }

    /**
     * Component Did Mount
     */
    componentDidMount() {
        const extref = this.props.match.params.extref;
        this.getCertificate(extref);
    }

    /**
     * Function get Certificate
     * @param {string} extref 
     */
    async getCertificate(extref){

        let response = await getCertificate(extref);

        if (!response.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response.message
            });
            return;
        }

        this.setState({
            emissionData: response.emissionData,
            luckNumber: response.luckNumber,
            certificateNumber: response.certificateNumber,
            policyNumber: response.policyNumber,
            validityStart: response.validityStart,
            validityEnd: response.validityEnd,
            fullName: response.fullName,
            cpf: response.cpf,
            dateOfBirth: response.dateOfBirth,
            phone: response.phone,
            products: response.products,
            iof: response.iof,
            monthlyInsurancePremium: response.monthlyInsurancePremium,  
            addressRisk: response.addressRisk,
            monthlyPrizeWithoutIOF: response.monthlyPrizeWithoutIOF,
            iofPrize: response.iofPrize,
            companyCode: response.companyCode,
            bundleId: response.bundleId,
            statusCertificate: response.statusCertificate,
            monthlyDraw: response.monthlyDraw,
            monthlyDrawText: response.monthlyDrawText,
            createdAt: response.createdAt
        }); 

        this.getTemplateCertificate(
            response.partnerId,
            response.companyCode,
            response.bundleId
        );     
    }

    /**
     * Function Get Template Certificate
     * @param {int} partner 
     * @param {int} companyCode 
     * @param {int} bundleId 
     */
    getTemplateCertificate(partner, companyCode, bundleId){
        
        /** VIVO CONTA PROTEGIDA */
        if(partner === 4 && companyCode === 373){
            this.setState({
                loading: false,
                displayCertificateVivoProtectedAccount: true
            });
            return;
        }

        /** VIVO SEGURO RESIDENCIAL */
        if(partner === 4 && companyCode === 374){
            this.setState({
                loading: false,
                displayCertificateVivoHomeInsurance: true
            });
            return;
        }        

        /** TIM CASA PROTEGIDA */
        if(partner === 7 && companyCode === 374 && bundleId === 1096){
            this.setState({
                loading: false,
                displayCertificateTimProtectedHouse: true
            });
            return;
        }

        /** TIM CONCIERGE */
        if(partner === 7 && companyCode === 374 && bundleId === 1099){
            this.setState({
                loading: false,
                displayCertificateTimConcierge: true
            });
            return;
        } 

        /** TIM HELP DESK */
        if(partner === 7 && companyCode === 374 && bundleId === 1100){
            this.setState({
                loading: false,
                displayCertificateTimHelpDesk: true
            });
            return;
        }
    }

    /**
     * Render Component
     */
    render() {

        const {
            loading,
            displayCertificateVivoProtectedAccount,
            displayCertificateVivoHomeInsurance,
            displayCertificateTimProtectedHouse,
            displayCertificateTimConcierge,
            displayCertificateTimHelpDesk,
            emissionData,
            luckNumber,
            certificateNumber,
            policyNumber,
            validityStart,
            validityEnd,
            fullName,
            cpf,
            dateOfBirth,
            phone,
            iof,
            createdAt,
            monthlyInsurancePremium,
            products,
            monthlyPrizeWithoutIOF,
            iofPrize,
            addressRisk,
            monthlyDraw,
            monthlyDrawText,
            statusCertificate
        } = this.state;

        return (
            <>
            <Loading display={loading} />

            { displayCertificateVivoProtectedAccount &&
                <CertificateVivoProtectAccount 
                    emissionData={emissionData}
                    luckNumber={luckNumber}
                    certificateNumber={certificateNumber}
                    policyNumber={policyNumber}
                    validityStart={validityStart}
                    validityEnd={validityEnd}
                    fullName={fullName}
                    cpf={cpf}
                    dateOfBirth={dateOfBirth}
                    phone={phone}
                    products={products}
                    iof={iof}
                    createdAt={createdAt}
                    monthlyInsurancePremium={monthlyInsurancePremium}
                    monthlyPrizeWithoutIOF={monthlyPrizeWithoutIOF}
                    iofPrize={iofPrize}
                    monthlyDraw={monthlyDraw}
                    monthlyDrawText={monthlyDrawText}        
                    statusCertificate={statusCertificate}
                /> 
            }

            { displayCertificateVivoHomeInsurance &&
                <CertificateVivoHomeInsurance 
                    emissionData={emissionData}
                    luckNumber={luckNumber}
                    certificateNumber={certificateNumber}
                    policyNumber={policyNumber}
                    validityStart={validityStart}
                    validityEnd={validityEnd}
                    fullName={fullName}
                    cpf={cpf}
                    dateOfBirth={dateOfBirth}
                    phone={phone}
                    products={products}
                    iof={iof}
                    createdAt={createdAt}
                    monthlyInsurancePremium={monthlyInsurancePremium}
                    addressRisk={addressRisk}     
                    monthlyPrizeWithoutIOF={monthlyPrizeWithoutIOF}
                    iofPrize={iofPrize}
                    monthlyDraw={monthlyDraw}
                    monthlyDrawText={monthlyDrawText}  
                    statusCertificate={statusCertificate}   
                />
            }

            { displayCertificateTimProtectedHouse &&
                <CertificateTimProtectedHouse 
                    emissionData={emissionData}
                    luckNumber={luckNumber}
                    certificateNumber={certificateNumber}
                    policyNumber={policyNumber}
                    validityStart={validityStart}
                    validityEnd={validityEnd}
                    fullName={fullName}
                    cpf={cpf}
                    dateOfBirth={dateOfBirth}
                    phone={phone}
                    products={products}
                    iof={iof}
                    monthlyInsurancePremium={monthlyInsurancePremium}
                    addressRisk={addressRisk}
                    monthlyPrizeWithoutIOF={monthlyPrizeWithoutIOF}
                    iofPrize={iofPrize}
                    monthlyDraw={monthlyDraw}
                    monthlyDrawText={monthlyDrawText}  
                    statusCertificate={statusCertificate} 
                />
            }

            { displayCertificateTimConcierge &&
                <CertificateTimConcierge 
                    emissionData={emissionData}
                    luckNumber={luckNumber}
                    certificateNumber={certificateNumber}
                    policyNumber={policyNumber}
                    validityStart={validityStart}
                    validityEnd={validityEnd}
                    fullName={fullName}
                    cpf={cpf}
                    dateOfBirth={dateOfBirth}
                    phone={phone}
                    products={products}
                    iof={iof}
                    monthlyInsurancePremium={monthlyInsurancePremium}
                    addressRisk={addressRisk}
                    monthlyPrizeWithoutIOF={monthlyPrizeWithoutIOF}
                    iofPrize={iofPrize}
                    monthlyDraw={monthlyDraw}
                    monthlyDrawText={monthlyDrawText}  
                    statusCertificate={statusCertificate} 
                />
            } 

            { displayCertificateTimHelpDesk &&
                <CertificateTimHelpDesk 
                    emissionData={emissionData}
                    luckNumber={luckNumber}
                    certificateNumber={certificateNumber}
                    policyNumber={policyNumber}
                    validityStart={validityStart}
                    validityEnd={validityEnd}
                    fullName={fullName}
                    cpf={cpf}
                    dateOfBirth={dateOfBirth}
                    phone={phone}
                    products={products}
                    iof={iof}
                    monthlyInsurancePremium={monthlyInsurancePremium}
                    addressRisk={addressRisk}
                    monthlyPrizeWithoutIOF={monthlyPrizeWithoutIOF}
                    iofPrize={iofPrize}
                    monthlyDraw={monthlyDraw}
                    monthlyDrawText={monthlyDrawText}  
                    statusCertificate={statusCertificate} 
                />
            }                                    
            </>
        );
    }
}