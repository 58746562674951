import React from 'react'
import Menu from '../components/Menu'
import Footer from '../components/Footer'

export default (props) => {
    return (
        <>
            <Menu />
                {props.children}
            <Footer />
        </>
    );
}