import React from 'react'
import IMAGE1 from './images/assina5.3.png'
import IMAGE2 from './images/assinatura6.3.jpg'
import IMAGE3 from './images/novo-logo-mapfre.jpg'
import IMAGE4 from './images/index.gif'
import IMAGE5 from './images/bg_cancelada.gif'

export default (props) => {
    return (
        <div>
            <table width={800} border={0} align="center" cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr>
                        <td height={20} align="right" valign="middle">
                            <a href="/#" className="borda" onClick={() => window.print()}>
                                <img alt="" src={IMAGE4} height={10} width={20} />Imprimir certificado
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            { props.statusCertificate === 'CANCELED' &&
                <table width={780} border={0} align="center" cellPadding={0} cellSpacing={0} style={{ marginBottom: 10 }}>
                    <tbody>
                        <tr>
                            <td>
                                <img src={IMAGE5} alt="" style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: '30%',
                                    width: '80%'
                                }} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
            <table width={595} border={0} align="center" cellPadding={0} cellSpacing={0} className="borda">
                <tbody>
                    <tr>
                        <td bgcolor="#CCCCCC"> </td>
                        <td align="right" valign="middle" bgcolor="#CCCCCC"> </td>
                        <td bgcolor="#CCCCCC"> </td>
                    </tr>
                    <tr>
                        <td width={32} height={100}> </td>
                        <td valign="bottom">
                            <table width={800} border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="80%" height={80} rowSpan={2}><h1 id="h1Certificado">Certificado Individual do Seguro</h1>
                                            <div style={{ paddingLeft: 3 }}>
                                                <strong>Seguro MAPFRE Casa Protegida</strong><br />
                                                <strong>Data de Emissão: </strong>{props.emissionData}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td width={13} />
                    </tr>
                    <tr>
                        <td width={32} align="center" />
                        <td height={120} align="left">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="50%">
                                            <p><strong>MAPFRE SEGUROS GERAIS S.A</strong><br />
                                                Proc. SUSEP nº 15414.004192/2004-71<br />
                                                CNPJ: 61.074.175/0001-38</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left"><strong>Certificado/Proposta Nº: </strong></td>
                                        <td width="25%" align="left"><strong>Apólice: </strong></td>
                                        <td width="25%" align="left"><strong>Grupo/Ramo: </strong></td>
                                    </tr>
                                    <tr>
                                        <td width="50%" height={20} align="left">{props.certificateNumber}</td>
                                        <td width="25%" height={20} align="left">{props.policyNumber}</td>
                                        <td width="25%" height={20} align="left">14 – Compreensivo residencial</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left">
                                            <strong>Vigência início às 24h do dia:</strong>
                                        </td>
                                        <td width="50%" align="left">
                                            <strong>Término de Vigência às 24h do dia:</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="39%" height={20} align="left">
                                            {props.validityStart}
                                        </td>
                                        <td width="39%" height={20} align="left">
                                            {props.validityEnd}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={32} align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={15} colSpan={2} valign="top"><strong>Dados do Estipulante</strong></td>
                                    </tr>
                                    <tr>
                                        <td width="17%" height={15} valign="top">TIM CELULAR S.A</td>
                                        <td width="17%" height={15} valign="top">CNPJ: 04.206.050/0001-80</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={15} colSpan={2} valign="top"><strong>Dados do(a) Segurado(a) Titular</strong></td>
                                    </tr>
                                    <tr>
                                        <td width="17%" height={15} valign="top">Nome:</td>
                                        <td width="17%" height={15} valign="top">CPF:</td>
                                    </tr>
                                    <tr>
                                        <td height={15} valign="top">{props.fullName}</td>
                                        <td height={15} valign="top">{props.cpf}</td>
                                    </tr>
                                    <tr>
                                        <td width="17%" height={15} valign="top">Data Nascimento:</td>
                                        <td width="17%" height={15} valign="top">Telefone (DDD):</td>
                                    </tr>
                                    <tr>
                                        <td>{props.dateOfBirth}
                                        </td>
                                        <td>{props.phone}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={32} align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={15} colSpan={2} valign="top"><strong>Local do Risco</strong></td>
                                    </tr>
                                    <tr>
                                        <td height={17} valign="top">Endereço:</td>
                                        <td height={17} valign="top">CEP:</td>
                                    </tr>
                                    <tr>
                                        <td width="17%" height={15} valign="top">{props.addressRisk.address}</td>
                                        <td width="17%" height={15} valign="top">{props.addressRisk.cep}</td>
                                    </tr>
                                    <tr>
                                        <td height={15} valign="top">Bairro/Cidade:</td>
                                        <td height={15} valign="top">UF:</td>
                                    </tr>
                                    <tr>
                                        <td height={15} valign="top">{props.addressRisk.neighborhood} {props.addressRisk.city}</td>
                                        <td height={15} valign="top">{props.addressRisk.uf}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={32} align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={15} colSpan={3} valign="top" className="titulo">Cobertura(s) contratada(s), valor(es) máximo(s) de indenização e prêmio(s)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table id="sublist" className="rf-dt headerTable bordaBotom" style={{ width: '100%' }}>
                                                <colgroup span={4} />
                                                <thead id="sublist:th" className="rf-dt-thd">
                                                    <tr id="sublist:0" className="rf-dt-hdr rf-dt-hdr-fst even-row">
                                                        <th id="sublist:j_idt52" className="rf-dt-hdr-c" style={{ width: '35%', textAlign: 'left' }}>Coberturas</th>
                                                        <th id="sublist:j_idt54" className="rf-dt-hdr-c" style={{ width: '25%' }}>Limites máximos de indenizações</th>
                                                        <th id="sublist:j_idt56" className="rf-dt-hdr-c">Franquia</th>
                                                        <th id="sublist:j_idt58" className="rf-dt-hdr-c">Valor do prêmio do seguro por cobertura</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="sublist:tb" className="rf-dt-b">
                                                    {props.products.map((response, key) => {
                                                        return <tr id={`sublist:${key}`} key={key} className="rf-dt-r rf-dt-fst-r even-row">
                                                            <td id="sublist:0:j_idt50" className="rf-dt-c">
                                                                {response.name}</td>
                                                            <td id="sublist:0:j_idt52" className="rf-dt-c textCenter">
                                                                {response.value}</td>
                                                            <td id="sublist:0:j_idt58" className="rf-dt-c textCenter">
                                                                {response.deductible}</td>
                                                            <td id="sublist:0:j_idt54" className="rf-dt-c textCenter">
                                                                {response.coverage_value}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                                <tbody id="sublist:sc">
                                                    <tr>
                                                        <td style={{ display: 'none' }} />
                                                    </tr>
                                                </tbody>
                                            </table>
                                            *Franquia - mínimo de R$ 150,00
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={32} align="center"> </td>
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="22%" height={15} valign="top" align="center">Prêmio mensal (sem IOF)</td>
                                        <td width="20%" height={15} valign="top" align="center">IOF - {props.iof}%
                                        </td>
                                        <td width="22%" height={15} valign="top" align="center">Prêmio mensal do seguro</td>
                                        <td width="35%" height={15} valign="top" align="center">Custeio</td>
                                    </tr>
                                    <tr>
                                        <td height={15} valign="top" align="center">
                                            <strong>{props.monthlyPrizeWithoutIOF}</strong>
                                        </td>
                                        <td valign="top" align="center">
                                            <strong>{props.iofPrize}</strong>
                                        </td>
                                        <td valign="top" align="center">
                                            <strong>{props.monthlyInsurancePremium}</strong>
                                        </td>
                                        <td valign="top" align="center">
                                            <strong>100% responsabilidade do Segurado</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={32} align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={80} width="100%" colSpan={3}>
                                            <p align="justify"><b>Forma de pagamento:</b> Conforme condições contratuais</p>
                                            <p align="justify"><b>Importante:</b> A vigência do risco e a cobertura do seguro iniciar-se-ão a partir das 24 (vinte e quatro) horas da data indicada neste documento. Em caso de não pagamento de qualquer parcela, o seguro estará sujeito ao cancelamento.</p>
                                            <p align="justify">As informações referentes à contratação do presente seguro foram prestadas com exatidão, boa fé e veracidade e assumido pelo cliente integral responsabilidade pelas mesmas.</p>
                                            <p align="justify">As Condições Gerais deste Seguro, bem como as condições de Assistência Residencial,  também poderão ser visualizadas no site www.mapfre.com.br/mapfre-tim </p>
                                            <p align="justify">Seguro garantido pela MAPFRE SEGUROS GERAIS S.A – Av das Nações Unidas, 14261 - 29º Andar - Ala A - São Paulo - SP - Proc. SUSEP nº 15414.004192/2004-71 - CNPJ: 61.074.175/0001-38 e comercializado pela FS INSURANCE ADMINISTRADORA E CORRETORA DE SEGUROS LTDA – CNPJ 01.241.78901.241.789/0001-35.  – Proc. SUSEP: 100288497. O segurado poderá consultar a situação cadastral de seu corretor de seguros, no site www.susep.gov.br ou pelo 0800 021 8484, por meio do número de seu registro na SUSEP, nome completo, CNPJ ou CPF. Este seguro possui pró-labore de 37,5% do prêmio líquido, pago ao estipulante. Em atendimento à Lei 12.741/12 informamos que incidem as alíquotas de 0,65% de PIS/Pasep e de 4% de COFINS sobre os prêmios de seguros, deduzidos do estabelecido em legislação específica. O registro deste plano na SUSEP não implica, por parte da Autarquia, incentivo ou recomendação a sua comercialização. As condições contratuais/regulamento deste produto protocolizadas pela sociedade/entidade junto à SUSEP poderão ser consultadas no endereço eletrônico www.susep.gov.br, de acordo com o número de processo constante da apólice/proposta. SUSEP – Superintendência de Seguros Privados – Autarquia Federal responsável pela fiscalização, normatização e controle dos mercados de seguro, previdência complementar aberta, capitalização, resseguro e corretagem de seguros.</p>
                                            <p align="justify">Para informações, dúvidas, reclamações, cancelamento e abertura de sinistro, ligue:</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={32} align="center" />
                        <td align="center" valign="top">
                            <table width="100%" border={0} cellPadding={5} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={15} colSpan={4} valign="top" className="titulo">Central de relacionamento e SAC</td>
                                    </tr>
                                    <tr style={{ background: '#E4E4E4', height: 40 }}>
                                        <th align="center"><strong>SAC</strong><br />24 Horas</th>
                                        <th align="center"><strong>Sinistro</strong><br />24 Horas</th>
                                    </tr>
                                    <tr className="even-row">
                                        <td align="center">*144</td>
                                        <td align="center">0800 667 4624</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            <p><strong>E-mail:</strong> tim@faleconoscoseguros.com.br</p>
                                            <p align="justify">A Ouvidoria poderá ser acionada para atuar na defesa dos direitos dos consumidores, para prevenir, esclarecer e solucionar conflitos não atendidos pelos canais de atendimento habituais. Contato: 0800 775 1079, em horário comercial ou pelo site www.mapfre.com.br. Atendimento a Deficientes Auditivos e de Fala: 0800 962 7373.</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td width={32} align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={0} cellSpacing={10} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <div align="center" style={{ textAlign: 'center' }}><img alt="" src={IMAGE1} style={{ width: 160, position: 'relative', top: 22 }} />
                                                <div align="center" style={{ textAlign: 'center', position: 'relative', top: 25 }}><span className="fontDefaultSpacing">Raphael de Luca Junior</span></div>
                                                <div align="center" style={{ textAlign: 'center', position: 'relative', top: 25 }}><span className="fontDefaultSpacing">Diretor</span></div>
                                            </div>
                                        </td>
                                        <td valign="top">
                                            <div align="center" style={{ textAlign: 'center' }}><img alt="" src={IMAGE2} />
                                                <div align="center" style={{ textAlign: 'center' }}><span className="fontDefaultSpacing">Augustín David B. C. Valdés</span></div>
                                                <div align="center" style={{ textAlign: 'center' }}><span className="fontDefaultSpacing">Diretor</span></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td colSpan={3} align="right"><img alt="" src={IMAGE3} height={70} style={{ paddingTop: 7, paddingRight: 7, paddingBottom: 4 }} width={190} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}