import React from 'react'

const STYLE1 = {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 60,
    lineHeight: '60px',
    backgroundColor: 'rgb(236, 28, 35)'
}

const STYLE2 = {
    fontSize: 12,
    color: '#ffffff',
    textAlign: 'center',
    marginBottom: 0
}

export default () => {
    return (
        <footer style={STYLE1}>
            <p style={STYLE2}>MAPFRE © 2016 Todos os direitos reservados</p>
        </footer>
    )
}