import React from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap'
import IMAGE1 from './images/parceiros-mapfre.png'
import IMAGE2 from './images/download.png'

const STYLE1 = {
    width: '100%',
    maxWidth: 900,
    padding: 15,
    margin: '10px auto'
}

const STYLE3 = {
    color: '#1f1f1f',
    fontSize: 17,
    lineHeight: '22px',
    fontWeight: 'bold'
}

const STYLE4 = {
    fontSize: 12,
    lineHeight: '22px'
}

export default (props) => {
    return (
        <Container fluid="md" style={STYLE1}>
            <Row>
                <Col><h5 style={STYLE3}>MINHA CARTEIRINHA</h5></Col>
            </Row>
            <Row>
                <Col md={5}>
                    <div
                        style={{
                            backgroundImage: `url(${IMAGE2})`,
                            backgroundRepeat: 'no-repeat',
                            height: 200,
                            marginBottom: 15
                        }}
                    >
                    <p style={{ padding: '70px 20px 0px', color: '#ffffff', fontSize: 14 }}>
                        <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 10 }}>{props.rows[0].fullName.toUpperCase()}</span><br />
                        <span>CPF: {props.rows[0].cpf}</span><br />
                        <span>Apólice: {props.rows[0].insurancePolicyNumber}</span><br />
                        <span>Início Vigência: {props.rows[0].validityStart}</span><br />
                        <span>Valido Até: {props.rows[0].validityEnd}</span><br />
                    </p>
                    </div>
                    <Button variant="primary" onClick={(e) => props.onClickButtonPrint(
                        e,
                        props.rows[0].fullName.toUpperCase(),
                        props.rows[0].cpf,
                        props.rows[0].insurancePolicyNumber,
                        props.rows[0].validityStart,
                        props.rows[0].validityEnd
                    )} >IMPRIMIR/ SALVAR PDF</Button>{' '}
                    <Button variant="primary" onClick={(e) => props.onClickButtonPrint(
                        e,
                        props.rows[0].fullName.toUpperCase(),
                        props.rows[0].cpf,
                        props.rows[0].insurancePolicyNumber,
                        props.rows[0].validityStart,
                        props.rows[0].validityEnd
                    )}>BAIXAR PNG</Button>
                </Col>
                <Col md={7}>
                    <p style={STYLE4}>Aproveite os benefícios salvando sua carteirinha como imagem para guardar na galeria do seu celular ou imprimir. São benefícios exclusivos! Acesse <a href="https://clubmapfre.com.br" rel="noopener noreferrer" target="_blank">www.clubmapfre.com.br</a> para conferir.</p>
                    <Image src={IMAGE1} alt="Parceiros Mapfre" fluid style={{ border: '1px solid #e2dede', }} />
                </Col>
            </Row>

        </Container>
    );
}