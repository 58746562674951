import React from 'react'
import reactStringReplace from 'react-string-replace'
import './styles.css'

export default (props) => {
    return (
        <div className="div-error" style={{ display: `${(props.display)? 'block': 'none'}` }}>
            <div className="close-error" onClick={props.onCloseErrorMessage}>
                X
            </div>
            <div>
                {reactStringReplace(props.message, /(https?:\/\/\S+)/g, (match, i) => (
                    <a key={match + i} rel="noopener noreferrer" target='_blank' href={match}>
                        aqui
                    </a>
                ))}
            </div>
        </div>
    );
};