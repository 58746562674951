/** FIREBASE ENVIRONMENT */
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
export const FIREBASE_MESSAGING_SEND_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SEND_ID
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

/** APPLICATION ENVIRONMENT */
export const NODE_ENV = process.env.NODE_ENV
export const URL_BACKEND = process.env.REACT_APP_URL_BACKEND
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY
export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME
export const REQUESTER_NAME = process.env.REACT_APP_REQUESTER_NAME