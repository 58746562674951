import React from 'react'

import IMAGE1 from './images/assina5.3.png'
import IMAGE2 from './images/assinatura6.3.jpg'
import IMAGE3 from './images/novo-logo-mapfre.jpg'
import IMAGE4 from './images/index.gif'
import IMAGE5 from './images/bg_cancelada.gif'

export default (props) => {

    let hideFields = (
        new Date(props.createdAt.toString().replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3/$2/$1")) > new Date('2020/07/31')
    );
    let products_order = props.products.sort(function(a, b) {
        return parseInt(a.priority, 10) - parseInt(b.priority, 10);
    });

    return (
        <div>
            <table width={900} border={0} align="center" cellPadding={4} cellSpacing={0} style={{ marginBottom: 10 }}>
                <tbody>
                    <tr>
                        <td height={20} align="right" valign="middle">
                            <span onClick={() => window.print()} style={{ textDecoration: 'underline', fontSize: 12 }}>
                                <img src={IMAGE4} alt="" height={10} width={10} />
                                Imprimir certificado
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            { props.statusCertificate === 'CANCELED' &&
                <table width={900} border={0} align="center" cellPadding={4} cellSpacing={0} style={{ marginBottom: 10 }}>
                    <tbody>
                        <tr>
                            <td>
                                <img src={IMAGE5} alt="" style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: '30%',
                                    width: '80%'
                                }} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            }            
            <table width={900} border={0} align="center" cellPadding={4} cellSpacing={0} className="borda">
                <tbody>
                    <tr>
                        <td width={10} height={100}></td>
                        <td valign="bottom">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="80%" height={80} rowSpan={2}><h1 id="h1Certificado" style={{ fontWeight: 'bold' }}>Certificado Individual do Seguro</h1>
                                            <div style={{ paddingLeft: 3 }}>
                                                <strong>Seguro Proteção Residencial</strong><br />
                                                <strong>Data de Emissão: </strong>{props.emissionData}
                                            </div>
                                        </td>
                                        { !hideFields && 
                                        <td style={{ display: 0 }} width="80%" height={80} rowSpan={2}>
                                            <br /><strong>Número da Sorte</strong><br />
                                            <span className="tituloValorBold">{props.luckNumber}</span>
                                            <br /><strong>Sorteio Mensal</strong><br />
                                            <span className="tituloValorBold">{props.monthlyDraw}</span>
                                        </td>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td width={13}> </td>
                    </tr>
                    <tr>
                        <td width={10} align="center" />
                        <td height={120} align="left">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="50%">
                                            <p>
                                                <strong>MAPFRE SEGUROS GERAIS S.A</strong><br />
                                                Proc. SUSEP nº 15414.004192/2004-71<br />
                                                CNPJ: 61.074.175/0001-38
                                            </p>
                                        </td>
                                        { !hideFields && 
                                            <td colSpan={2}>
                                                <strong>MAPFRE CAPITALIZAÇÃO S.A</strong><br />
                                                Proc. Susep nº 15414.901422/2018-47<br />
                                                CNPJ: 09.382.998/0001-00
                                            </td>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="18%" height={20} align="left"><strong>Certificado Nº: </strong></td>
                                        <td width="18%" height={20} align="left"><strong>Apólice: </strong></td>
                                        <td width="33%" height={20} align="left"><strong>Ramo: </strong></td>
                                    </tr>
                                    <tr>
                                        <td width="30%" height={20} align="left">{props.certificateNumber}</td>
                                        <td width="30%" height={20} align="left">{props.policyNumber}</td>
                                        <td width="40%" height={20} align="left">14 - Compreensivo residencial</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left">
                                            <strong>Vigência início às 24h do dia:</strong>
                                        </td>
                                        <td width="50%" align="left">
                                            <strong>Término de Vigência às 24h do dia:</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="39%" height={20} align="left">{props.validityStart}
                                        </td>
                                        <td width="39%" height={20} align="left">{props.validityEnd}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={10} align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={15} colSpan={2} valign="top"><strong>Dados do Estipulante</strong></td>
                                    </tr>
                                    <tr>
                                        <td width="17%" height={15} valign="top">Telefônica Brasil</td>
                                        <td width="17%" height={15} valign="top">CNPJ: 02.558.157/0001-62</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={15} colSpan={2} valign="top"><strong>Dados do(a) Segurado(a)</strong></td>
                                    </tr>
                                    <tr>
                                        <td width="17%" height={15} valign="top">Nome:</td>
                                        <td width="17%" height={15} valign="top">CPF:</td>
                                    </tr>
                                    <tr>
                                        <td height={15} valign="top">{props.fullName}</td>
                                        <td height={15} valign="top">{props.cpf}</td>
                                    </tr>
                                    <tr>
                                        <td width="17%" height={15} valign="top">Data Nascimento:</td>
                                        <td width="17%" height={15} valign="top">Telefone (DDD):</td>
                                    </tr>
                                    <tr>
                                        <td>{props.dateOfBirth}</td>
                                        <td>{props.phone}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={10} align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={15} colSpan={2} valign="top"><strong>Local do Risco</strong></td>
                                    </tr>
                                    <tr>
                                        <td height={17} valign="top">Endereço:</td>
                                        <td height={17} valign="top">CEP:</td>
                                    </tr>
                                    <tr>
                                        <td width="17%" height={15} valign="top">{props.addressRisk.address} - {props.addressRisk.number} {props.addressRisk.complement !== "" && ` - ${props.addressRisk.complement}`}</td>
                                        <td width="17%" height={15} valign="top">{props.addressRisk.cep}</td>
                                    </tr>
                                    <tr>
                                        <td height={15} valign="top" attr={hideFields}>Bairro/Cidade:</td>
                                        <td height={15} valign="top">UF:</td>
                                    </tr>
                                    <tr>
                                        <td height={15} valign="top">{props.addressRisk.neighborhood} {props.addressRisk.city}</td>
                                        <td height={15} valign="top">{props.addressRisk.uf}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={10} align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={15} colSpan={3} valign="top" className="titulo">Cobertura(s) Contratada(s), valor(es) máximo(s) de indenização e valor(es) do seguro por cobertura:</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table id="sublist" className="rf-dt headerTable bordaBotom" style={{ width: '100%' }}><colgroup span={4} />
                                                <thead id="sublist:th" className="rf-dt-thd">
                                                    <tr id="sublist:0" className="rf-dt-hdr rf-dt-hdr-fst even-row">
                                                        <th id="sublist:j_idt50" className="rf-dt-hdr-c" style={{ width: '40%' }}>Coberturas</th>
                                                        <th id="sublist:j_idt52" className="rf-dt-hdr-c" style={{ width: '20%' }}>Limites máximos de idenizações</th>
                                                        <th id="sublist:j_idt54" className="rf-dt-hdr-c" style={{ width: '20%' }}>Franquia</th>
                                                        <th id="sublist:j_idt56" className="rf-dt-hdr-c" style={{ width: '40%' }}>Valor do  seguro por cobertura</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="sublist:tb" className="rf-dt-b">
                                                    {products_order.map((response, key) => {
                                                        return <tr id={`sublist:${key}`} key={key} className="rf-dt-r rf-dt-fst-r even-row">
                                                            <td id="sublist:0:j_idt50" className="rf-dt-c">
                                                                {response.name}</td>
                                                            <td id="sublist:0:j_idt52" className="rf-dt-c textCenter">
                                                                {response.value}</td>
                                                                <td id="sublist:0:j_idt58" className="rf-dt-c textCenter">
                                                                {response.deductible}</td>
                                                            <td id="sublist:0:j_idt54" className="rf-dt-c textCenter">
                                                                {response.coverage_value}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                                <tbody id="sublist:sc">
                                                    <tr><td style={{ display: 'none' }} />
                                                    </tr></tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={10} align="center" />
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="100%" colSpan={3}>
                                            <p>*Franquia - mínimo de R$ 150,00</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={10} align="center"> </td>
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td width="22%" height={15} valign="top" align="center">Prêmio mensal (sem IOF)</td>
                                        <td width="20%" height={15} valign="top" align="center">IOF - {props.iof}
                                        </td>
                                        <td width="22%" height={15} valign="top" align="center">Prêmio mensal do seguro</td>
                                        <td width="35%" height={15} valign="top" align="center">Custeio</td>
                                    </tr>
                                    <tr>
                                        <td height={15} valign="top" align="center">
                                            <strong>{props.monthlyPrizeWithoutIOF}</strong>
                                        </td>
                                        <td valign="top" align="center">
                                            <strong>{props.iofPrize}</strong>
                                        </td>
                                        <td valign="top" align="center">
                                            <strong>{props.monthlyInsurancePremium}</strong>
                                        </td>
                                        <td valign="top" align="center">
                                            <strong>100% responsabilidade do Segurado</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={10} align="center"> </td>
                        <td align="left" valign="top">
                            <table width="100%" border={0} cellPadding={4} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td height={15} valign="top"><strong>Forma de Pagamento: </strong>Conforme condições contratuais</td>
                                    </tr>
                                    <tr>
                                        <td valign="top">
                                            { !hideFields && 
                                                <p align="justify"><strong><u>Sorteio Mensal:</u></strong> O Segurado terá o direito de participar de 1 (um) sorteio mensal durante o período de vigência constante neste certificado, tendo como premiação individual, o valor de {props.monthlyDraw} ({props.monthlyDrawText}), bruto de Imposto de Renda. O Segurado concorrerá aos sorteios com o <b>NÚMERO DA SORTE</b> informado no certificado a partir do 2º (segundo) mês subsequente ao pagamento do Seguro, desde que posteriormente esteja com o pagamento em dia.</p>
                                            }
                                            <p align="justify">Importante: A vigência do risco e a cobertura do seguro iniciar-se-ão a partir das 24 (vinte e quatro) horas da data indicada neste documento. Em caso de não pagamento de qualquer parcela, o seguro estará sujeito ao cancelamento.</p>
                                            <p align="justify">As informações referentes à contratação do presente seguro foram prestadas com exatidão, boa fé e veracidade e assumido pelo cliente integral responsabilidade pelas mesmas.</p>
                                            <p align="justify">
                                                As Condições Gerais deste Seguro, bem como as condições de Assistência Residencial
                                                e Manual do segurado poderão ser visualizados no site www.mapfrevivo.com.br.
    </p>
                                            <p align="justify">Seguro garantido pela MAPFRE SEGUROS GERAIS S.A – Av das Nações Unidas, 14261 - 29º Andar - Ala A - São Paulo - SP - Proc. SUSEP nº 15414.004192/2004-71 - CNPJ: 61.074.175/0001-38 e comercializado pela Telefônica Corretora de Seguros Ltda – CNPJ 04.772.577/0001-72 – Proc. SUSEP: 100443484. O segurado poderá consultar a situação cadastral de seu corretor de seguros, no site www.susep.gov.br ou pelo 0800 021 8484, por meio do número de seu registro na SUSEP, nome completo, CNPJ ou CPF. Este seguro possui pró-labore de 30,25% do valor líquido, pago ao estipulante. Em atendimento à Lei 12.741/12 informamos que incidem as alíquotas de 0,65% de PIS/Pasep e de 4% de COFINS sobre os valores de seguros, deduzidos do estabelecido em legislação específica. O registro deste plano na SUSEP não implica, por parte da Autarquia, incentivo ou recomendação a sua comercialização. As condições contratuais/regulamento deste produto protocolizadas pela sociedade/entidade junto à SUSEP poderão ser consultadas no endereço eletrônico www.susep.gov.br, de acordo com o número de processo constante da apólice/proposta. SUSEP – Superintendência de Seguros Privados – Autarquia Federal responsável pela fiscalização, normatização e controle dos mercados de seguro, previdência complementar aberta, capitalização, resseguro e corretagem de seguros.</p>
                                            <p align="justify">Em caso de dúvida, reclamações, solicitações de cancelamento e captação de sinistro ligue para SAC Serviço de Atendimento ao Consumidor</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>

                    <tr>
                        <td width={10} align="center"> </td>
                        <td align="left" valign="top">
                        <table id="sublist" className="rf-dt headerTable bordaBotom" style={{ width: '100%' }}>
                                <colgroup span={2} />
                                <thead id="sublist:th" className="rf-dt-thd">
                                    <tr id="sublist:0" className="rf-dt-hdr rf-dt-hdr-fst even-row">
                                        <th className="rf-dt-hdr-c" style={{ textAlign: 'left' }}><strong style={{ fontSize: 12 }}>Capitais e regiões metropolitanas</strong></th>
                                        <th className="rf-dt-hdr-c" style={{ textAlign: 'left' }}><strong style={{ fontSize: 12 }}>Demais localidades</strong></th>
                                        <th className="rf-dt-hdr-c" style={{ textAlign: 'left' }}><strong style={{ fontSize: 12 }}>SAC 24 Horas</strong></th>
                                        <th className="rf-dt-hdr-c" style={{ textAlign: 'left' }}><strong style={{ fontSize: 12 }}>Deficiente auditivo</strong></th>                                        
                                    </tr>
                                </thead>
                                <tbody id="sublist:tb" className="rf-dt-b">
                                    <tr id="sublist:0" className="rf-dt-r rf-dt-fst-r even-row">
                                        <td className="rf-dt-c">4002 7041 </td>
                                        <td className="rf-dt-c">0800 570 7041</td>
                                        <td className="rf-dt-c">0800 570 7043</td>
                                        <td className="rf-dt-c">0800 775 5045</td>                                        
                                    </tr>
                                </tbody>
                                <tbody id="sublist:sc">
                                    <tr>
                                        <td style={{ display: 'none' }} />
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" border={0} cellPadding={5} cellSpacing={5} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <p><strong>E-mail:</strong> atendimentoaffinity@bbmapfre.com.br</p>
                                            <p align="justify">A Ouvidoria poderá ser acionada para atuar na defesa dos direitos dos consumidores, para prevenir, esclarecer e solucionar conflitos não atendidos pelos canais de atendimento habituais.” Contato: 0800 775 1079, em horário comercial ou pelo site www.mapfre.com.br. Atendimento a Deficientes Auditivos e de Fala: 0800 962 7373.</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" />
                    </tr>
                    <tr>
                        <td width={10} align="center"> </td>
                        <td align="left" valign="top" style={{ paddingTop: 30 }}> 
                            <table width="100%" border={0} cellPadding={4} cellSpacing={10} className="bordaBotom">
                                <tbody>
                                    <tr>
                                        <td valign="top" style={{ paddingBottom: 15 }}>
                                            <div align="center"><img src={IMAGE1} alt="" style={{ width: 185 }} />
                                                <div align="center" style={{ textAlign: 'center' }}><span className="fontDefaultSpacing">Raphael de Luca Junior</span></div>
                                                <div align="center" style={{ textAlign: 'center' }}><span className="fontDefaultSpacing">Diretor</span></div>
                                            </div>
                                        </td>
                                        <td valign="top" style={{ paddingBottom: 15 }}>
                                            <div align="center" style={{ textAlign: 'center' }}><img src={IMAGE2} alt="" />
                                                <div align="center" style={{ textAlign: 'center' }}><span className="fontDefaultSpacing">Augustín David B. C. Valdés</span></div>
                                                <div align="center" style={{ textAlign: 'center' }}><span className="fontDefaultSpacing">Diretor</span></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center"> </td>
                    </tr>
                    <tr>
                        <td colSpan={3} align="right"><img alt="" src={IMAGE3} height={70} style={{ paddingTop: 7, paddingRight: 7, paddingBottom: 4 }} width={190} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}