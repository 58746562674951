/**
 * Function Validator CPF
 * @param {int} cpf
 */
export function maskCPF(value) {
    value=value.replace(/\D/g,"")
    if (value.length <= 11) {
        value=value.replace(/(\d{3})(\d)/,"$1.$2") 
        value=value.replace(/(\d{3})(\d)/,"$1.$2")
        value=value.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
        return value;
    }
}